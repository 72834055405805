const forms = document.querySelectorAll('[data-form-submit]');

const sitekey = document.querySelector(`[data-sitekey]`)
let NOCAPTCHA_SITEKEY = ''
if (sitekey) {
    NOCAPTCHA_SITEKEY = sitekey.dataset.sitekey
}


if (forms) {
    forms.forEach(form => {
        form.addEventListener("submit", function(event) {
            const thisForm = form.dataset.formSubmit
            event.preventDefault();

            const modalInput = document.querySelectorAll('.modal input')
            modalInput.forEach(el => {
                el.addEventListener("input", e => {
                    const errorBlok = document.querySelector(`[data-error="${e.target.name}"]`)
                    errorBlok.innerHTML = ""
                })
            })

            document.querySelector(`#recaptcha-container-${thisForm} .g-recaptcha`).setAttribute("id", `recaptcha-${thisForm}`);

            const blockAllErrors = document.querySelectorAll(`[data-form-submit="${thisForm}"] [data-error]`)
            blockAllErrors.forEach(e => {
                e.innerHTML = ''
            })

            let fd = new FormData(form)

            const action = form.action
            const method = form.method

            var object = {};
            fd.forEach(function(value, key){
                object[key] = value;
            });
            var json = JSON.stringify(object);
            // console.log(json)

            fetch(action,{
                method: method,
                headers: {
                    'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
                    'Content-Type': 'application/json'
                },
                body: json
            }).then((response) => {
                return response.json();
            })
            .then((data) => {
                const captcha = `recaptcha-container-${thisForm}`
                document.getElementById(captcha).innerHTML = `<div class="g-recaptcha" id="recaptcha-${thisForm}"></div>`;
                // grecaptcha.reset(captcha)
                grecaptcha.render(`recaptcha-${thisForm}`, {
                    sitekey: NOCAPTCHA_SITEKEY
                });


                if (data.errors) {
                    const errors = data.errors

                    
                    for(const[key, value] of Object.entries(errors)) {
                        const blockError = document.querySelector(`[data-form-submit="${thisForm}"] [data-error="${key}"]`)
                        blockError.innerHTML = `<span></span><p class="error">${value}<p>`
                    }

                    const thisFormError = document.querySelector(`[data-form-submit="${thisForm}"] [data-error] p`)
                    const thisFormErrorScrollTo = document.querySelector(`[data-form-submit="${thisForm}"] [data-error] span`)
                    // console.log(thisFormError.getBoundingClientRect())

                    thisFormErrorScrollTo.scrollIntoView({behavior: "smooth"})
                    
                    // window.scrollTo({
                    //     top: thisFormError.getBoundingClientRect().top,
                    //     behavior: "smooth",
                    // })
                    

                }

                if (data.status === true) {
                    const modal = document.querySelector('.modal');
                    const body = document.querySelector('body');

                    const thanks = document.querySelector('.thanks');
                    const thanks__text = document.querySelector('.thanks__text');

                    body.classList.remove('noScroll')
                    modal.classList.remove('activeModal')

                    thanks.classList.add('thanks_active')

                    thanks__text.innerHTML = data.message
                    
                    dataLayer.push({'event': 'lid_form'});
                }
            });
        })
    })

}
