import datepicker from 'js-datepicker'
import 'js-datepicker/src/datepicker';

const picker = datepicker('.modal__datepicker',{
    customDays: [ 'НД','ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ' ],
    customMonths: ['Січень', 'Лютий', 'Березнь', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
    // showAllDates: true,
    position: 'br',
    // value : date.toDateString(),
    minDate: new Date(),
    // noWeekends: true,
    startDay: 1,

    formatter: (input, date, instance) => {
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const formattedToday = dd + '.' + mm + '.' + yyyy;

        input.value = formattedToday // => '1/1/2099'
    }

    // disabler: date => date.getDay() === 5,
    // disabler: date => date.getDay() === 7,
})
let pickerTriger = document.querySelector('.modal__datepicker-login')
if(pickerTriger) {
    const pickerLogin = datepicker(pickerTriger,{
        customDays: [ 'НД','ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ' ],
        customMonths: ['Січень', 'Лютий', 'Березнь', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
        // showAllDates: true,
        position: 'br',
        // value : date.toDateString(),
        minDate: new Date(),
        // noWeekends: true,
        startDay: 1,

        formatter: (input, date, instance) => {
            const yyyy = date.getFullYear();
            let mm = date.getMonth() + 1; // Months start at 0!
            let dd = date.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            const formattedToday = dd + '/' + mm + '/' + yyyy;

            input.value = formattedToday // => '1/1/2099'
        }

        // disabler: date => date.getDay() === 5,
        // disabler: date => date.getDay() === 7,
    })
}


